import { Inject, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { SESSION_STORAGE, StorageService, StorageTranscoders } from 'ngx-webstorage-service';
import { rejects } from 'assert';
import { DatePipe } from '@angular/common';




@Injectable()

export class HttpService {

  HAS_LOGGED_IN = 'hasLoggedIn';
  public base64Image: any;
  d: any; n: any;

  constructor(private httpClient: HttpClient, @Inject(SESSION_STORAGE) private storage: StorageService) {
    this.d = new Date(),
      this.n = this.d.getTime();
  }

  getDateCurrent():Promise<any>{
    return new Promise<any>((resolve,reject)=>{
      let dateNow = new Date()
      let datePipe = new DatePipe('en-US')
      let date = {
        defaultYear:datePipe.transform(dateNow,"y",'UTC+7'),
        defaultMonth:datePipe.transform(dateNow,"M",'UTC+7'),
        defaultDay:datePipe.transform(dateNow,"d",'UTC+7'),
        dateCurrent:datePipe.transform(dateNow,"yyyy-MM-dd",'UTC+7')
      }
      resolve(date)
    });
  }

  getDate(dateNow:Date):Promise<any>{
    return new Promise<any>((resolve,reject)=>{
      let datePipe = new DatePipe('en-US')
      let date = {
        defaultYear:datePipe.transform(dateNow,"y",'UTC+7'),
        defaultMonth:datePipe.transform(dateNow,"M",'UTC+7'),
        defaultDay:datePipe.transform(dateNow,"d",'UTC+7'),
        dateCurrent:datePipe.transform(dateNow,"yyyy-MM-dd",'UTC+7')
      }
      resolve(date)
    });
  }

  verifiedToken(token: string) : Promise<any>{
    return new Promise<any>((resolv, reject)=>{
      let value = "token=" + token + "&client_id=" + environment.client_id + "&client_secret=" + environment.client_secret;
      this.getHttpUrlEncode('auth/realms/thaisummit/protocol/openid-connect/token/introspect', value).then(data => {
        resolv(data);
        reject(new Error("Verified token failed."))
      });
    });
  }

  //get session
  getLocalStorageInfo(name: string) : Promise<any>{
    return new Promise<any>((resolv, reject)=>{
      resolv(this.storage.get(name));
      reject(new Error("Get local storage failed."));
    });
  }

  //set session login
  setLocalStorageOne(name: string, value: string) : Promise<any> {
    return new Promise<any>((resolve, reject)=>{
      this.storage.set(name, value);
      resolve("Set local storage  success.");
      reject(new Error("Set local storage failed."));
    });
  };

  //set session login
  setLocalStorageLogin(id:string,token: string, userType: string, userName: string, scope: string, pwdLi: string) : Promise<any>{
    return new Promise((resolve, reject)=>{

      this.storage.set('HAS_LOGGED_IN', this.HAS_LOGGED_IN);
      resolve("Set local storage login success.");
      reject(new Error("Set local storage login failed."));
    });
  };

  setLocalStorageToken(token: string): Promise<any> {
    return new Promise((resolve, reject)=>{
      this.storage.remove('token');
      this.storage.set('token', token);
      resolve("Set local storage success.");
      reject(new Error("Set local storage failed."));
    });
  };

  //remove session login
  logout() : Promise<any>{
    return new Promise((resolve, reject)=>{
      this.storage.remove(this.HAS_LOGGED_IN);
      this.storage.remove('token');
      this.storage.remove('user_type');
      this.storage.remove('username');
      this.storage.remove('scope');
      this.storage.remove('lang');
      resolve("Remove local storage success.");
      reject(new Error("Remove local storage failed."));
    })
  };

  getHttpUrlEncode(url:string, value:any) : Promise<any> {
    return new Promise((resolve,reject)=>{
      let reqOpts = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        params: new HttpParams()
      };

      
  
      this.httpClient.post(environment.ssoBaseUrl + '/' + url, value, reqOpts).subscribe(res => {
        //this.loadingDismiss();
        resolve({'error': false, 'result': res });
      }, (error) => {
        reject({ 'error': true, 'message': error });
      })
    })

  }

  getHttpJsonGetBasicAuth(url, user,password) : Promise<any> {
    let self = this;
    return new Promise((resolve,reject)=>{
      let reqOpts = {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Basic ' + btoa(user+':'+password)
        }
      };
      //get request for data
      self.httpClient.get(url, reqOpts).subscribe(res => {
        resolve({'error': false, 'result': res });
      }, (error) => {
        reject({ 'error': true, 'message': error });
      })
    })
  }

  getHttpJsonGet(url, token) : Promise<any> {
    let self = this;
    return new Promise((resolve,reject)=>{
      let reqOpts = {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      };
      //get request for data
      self.httpClient.get(environment.apiUrl + '/' + url, reqOpts).subscribe(res => {
        resolve({'error': false, 'result': res });
      }, (error) => {
        reject({ 'error': true, 'message': error });
      })
    })
  }

  getHttpJsonPost(url, value, token): Promise<any> {
    return new Promise((resolve,reject)=>{
      let reqOpts = {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      };
      this.httpClient.post(environment.apiUrl + '/' + url, value, reqOpts).subscribe(res => {
       resolve({'error': false, 'result': res });
      }, (error) => {
       reject({ 'error': true, 'message': error });
      })
    })
  }

  getHttpJsonPostLine(url, value, token): Promise<any> {
    return new Promise((resolve,reject)=>{
      let reqOpts = {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      };
      this.httpClient.post(url, value, reqOpts).subscribe(res => {
       resolve({'error': false, 'result': res });
      }, (error) => {
       reject({ 'error': true, 'message': error });
      })
    })
  }

  getHttpJsonPut(url:string, value:{}, token:string): Promise<any> {
    return new Promise((resolve,reject)=>{
      let reqOpts = {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      };
      this.httpClient.put(environment.apiUrl + '/' + url, value, reqOpts).subscribe(res => {
       resolve({'error': false, 'result': res });
      }, (error) => {
       reject({ 'error': true, 'message': error });
      })
    })

  }


  getHttpJsonPatch(url:string, value:{}, token:string): Promise<any> {
    return new Promise((resolve,reject)=>{
      let reqOpts = {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      };
      this.httpClient.patch(environment.apiUrl + '/' + url, value, reqOpts).subscribe(res => {
       resolve({'error': false, 'result': res });
      }, (error) => {
       reject({ 'error': true, 'message': error });
      })
    })

  }

  getHttpJsonDelete(url:string, id:string, token:string): Promise<any> {
    return new Promise((resolve,reject)=>{
      let reqOpts = {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      };
      this.httpClient.delete(environment.apiUrl + '/' + url+ '/'+id, reqOpts).subscribe(res => {
       resolve({'error': false, 'result': res });
      }, (error) => {
       reject({ 'error': true, 'message': error });
      })
    })

  }

  fetchHttpPostUpload(url,fileType,value,cb){
    let reqOpts = {
      headers: {'filepath':fileType}
    };
    this.httpClient.post(environment.apiUrl + '/' + url, value, reqOpts).subscribe(res => {
      return cb({ 'result': res, 'error': false });
    }, (error) => {
      return cb({ 'error': true,'message':error });
    })
    }

}
