export const environment = {
  production: false,
  hmr: false,
  apiUrl:"https://korn.ths-true.com/api",
  baseUrl:"https://korn.ths-true.com/api",
  imagePath:"https://korn.ths-true.com/uploads",
  ssoBaseUrl:"https://korn.ths-true.com/api",
  
  gisUrl:"https://wlittt.com:8098/Realtime/sjct/sjct",
  gisUser : "SJCT",
  gisPassword:"UKZHQNFB3",


  // apiUrl:"http://localhost:4000/api",
  // baseUrl:"http://localhost:4000/api",
  // imagePath:"http://localhost:4000/uploads",
  // ssoBaseUrl:"http://localhost:4000/api",
  
  sysName:"KORN Delivery managment",
  sysVersion:"1.0",
  client_id:"korn-client",
  client_secret:"20bd501c-7276-4c1f-a687-b08d8779174e",
  regis_secret:"dm1zbG9jYWw6UEBzc3cwcmQ=",
  siteKeyCapCha:"6LdAW6olAAAAAP8C0yYpyTL18hrwZTv0U_mdjR0d",
  //googleMapKey:"AIzaSyAlQCXgab7H0o63Psf0tK69Srpoxpl_snM",
  googleMapKey:"AIzaSyDXTRyTxgpqDLMPpfLKzhvA5o9LMA0vkC8",
  realm_id:"korntracking",
  grant_type:"password"
};
